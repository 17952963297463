<template>
  <title>Overzicht | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="d-md-none d-block col-12 du-column-bg-white" :class="{'cptl cpbl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cpm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="du-content-title du-data-title mt-0 mb-4">
              <h2>AANVRAGEN</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 mb-1">
            <div class="du-data-overview-datatitle">
              <h3>ID</h3>
            </div>
          </div>

          <div class="col-5 mb-1">
            <div class="du-data-overview-datatitle">
              <h3>DATUM</h3>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12 d-flex justify-content-center">
            <div class="du-data-overview-seperator-thick"></div>
          </div>

          <div v-for="opt in tests" class="col-12">
            <div class="du-data-overview-data">
              <div class="row">
                <div class="col-5 col-md-5 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <h4>{{opt.id}}</h4>
                  </div>
                </div>
                <div class="col-5 col-md-4 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <p>{{ opt.date }}</p>
                  </div>
                </div>
                <div class="col-2 col-md-3 d-flex align-items-center">
                  <div v-if="!windowCheckerData.isMobileScreen && windowCheckerData.isTabletScreen" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-end">
                    <router-link :to="{name: 'QuestionList', params:{id: opt.id}}">
                      <h4>NAAR TOETS <i class="fa-solid fa-chevron-right thick-icon"></i></h4>
                    </router-link>
                  </div>
                  <span v-if="windowCheckerData.isMobileScreen">
                          <div class="download-pdf d-flex flex-column align-items-center">
                            <span>
                              <i class="fa-solid fa-file-pdf"></i>
                              <!--                              <i class="fa-solid fa-download"></i>-->
                              <!--                              <i class="fa-solid fa-file-arrow-down"></i>-->
                            </span>
                          </div>
                        </span>
                </div>
              </div>
            </div>
            <div class="du-data-overview-seperator"></div>
          </div>

          <div v-if="!tests.length" class="col-12">
            <div class="du-data-overview-data">
              <h3>ER ZIJN NOG GEEN AANVRAGEN UITGEVOERD.</h3>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-6 col-12" :class="{'cptl cpxxl cpbm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
            <h1>OVERZICHT</h1>
          </div>
        </div>
        <div class="du-content-fulltext cmbm">
          <p>Dit is het overzicht van al uw aanvragen. Wil uw een nieuwe aanvraag doen? Klik dan op onderstaande knop. Wanneer de betaling voldaan is, kunt u van start gaan met uw aanvraag.
            <br><br>Let op! Als gevolg van de huidige AVG-wetgeving is een aanvraag na het starten slechts 24 uur beschikbaar om tussentijds te staken of op een later tijdstip te hervatten. Hierna zal deze komen te vervallen als deze niet juist is verzonden.
          </p>
        </div>
        <div class="du-question-control-left d-inline-block">
              <router-link to="/nieuwe-aanvraag">
                <button class="du-button">NIEUWE AANVRAAG
                  <i class="fa-solid fa-chevron-right thick-icon"></i>
                </button>
              </router-link>
            </div>
      </div>

      <div class="d-md-block d-none col-md-6 col-12 du-column-bg-white" :class="{'cptl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="du-content-title du-data-title mt-0 mb-4">
              <h2>AANVRAGEN</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 col-lg-5 d-flex align-items-center">
            <div class="du-data-overview-datatitle">
              <h3>ID</h3>
            </div>
          </div>

          <div class="col-5 col-lg-4 d-flex align-items-center">
            <div class="du-data-overview-datatitle">
              <h3>DATUM</h3>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12 d-flex justify-content-center">
            <div class="du-data-overview-seperator-thick"></div>
          </div>
          <div v-for="opt in tests" class="col-12">
            <div class="du-data-overview-data">
              <div class="row">
                <div class="col-5 col-lg-5 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <h4>{{opt.id}}</h4>
                  </div>
                </div>
                <div class="col-5 col-lg-4 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <p>{{ opt.date }}</p>
                  </div>
                </div>
                <div class="col-2 col-lg-3 d-flex align-items-center">
                  <div v-if="!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-end">
                    <router-link :to="{name: 'QuestionList', params:{id: opt.id}}">
                      <h4>NAAR TOETS <i class="fa-solid fa-chevron-right thick-icon"></i></h4>
                    </router-link>                  </div>
                  <span v-if="windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen">
                    <div class="download-pdf d-flex flex-column align-items-center">
                      <span>
                        <i class="fa-solid fa-file-pdf"></i>
                        <!--                              <i class="fa-solid fa-download"></i>-->
                        <!--                              <i class="fa-solid fa-file-arrow-down"></i>-->
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="du-data-overview-seperator"></div>
          </div>

          <div v-if="!tests.length" class="col-12">
            <div class="du-data-overview-data">
              <h3 v-if="finishedDbCall && !tests.length">ER ZIJN NOG GEEN AANVRAGEN UITGEVOERD.</h3>
              <h3 v-if="isLoading && !finishedDbCall">LADEN VAN AANVRAGEN...</h3>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import data from "bootstrap/js/src/dom/data";
import {useAuth0} from "@auth0/auth0-vue";


export default {

  name: "RequestOverview",
  components: {WindowChecker},

  setup() {
    const auth0 = useAuth0();
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
    }
  },

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      tests: [],
      finishedDbCall: false
    }
  },

  watch: {
    isLoading: function (value) {
      if (value === false) {
        this.getRequests()
      }
    }
  },


  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },

    redirectToPDF(token) {
      console.log('pdf')
      this.$router.push('/pdf?token='+token)
    },

    async getRequests() {

      // let flArray2 =
      // {
      //   selectAll: "true",
      //   from: "drv_finishedTests",
      //   where: {
      //     st1: "userID = '" + this.user.sub + "'",
      //   },
      //   orderBy: "id DESC",
      //   testQuery: "false",
      // }
      //
      // const requestOptions2 = {
      //   method: "POST",
      //   headers: {"Content-Type": "application/json"},
      //   body: JSON.stringify(flArray2),
      // };
      //
      // const response = await fetch("https://nodejs.imaginecreativeagency.nl/drv/dynamicGet/index.php", requestOptions2);
      // let dataArr = await response.json();
      // console.log(dataArr)
      // this.finishedDbCall = true
      //
      // if (dataArr['success'] === true && dataArr['data'] !== false) {
      //   this.finishedTests = dataArr['data']
      // }
      // this.createNewTest()
      const storages = localStorage.getItem('drv_tests')
      if(storages){
        this.tests = JSON.parse(storages);
      }

    },

    createNewTest(){

      const date = new Date();
      const now = date.getTime();
      const randomPart = Math.random().toString(36).substring(2, 9);  // Genereert een willekeurige string
      const uniqueId = `${now}_${randomPart}`;
      const obj = {
        id: uniqueId,
        date: this.formatDate(date),
        questionProgress: JSON.stringify([]),
        calculationData: JSON.stringify([])
      }
      const storage = localStorage.getItem('drv_tests')
      let arr;
      if(storage){
        arr = JSON.parse(storage);
        arr.push(obj)
      }
      else{
        arr = []
        arr.push(obj)
      }
      localStorage.setItem('drv_tests', JSON.stringify(arr));
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Maanden beginnen bij 0
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },


    returnFormattedDate(recDate) {

      const isoDate = recDate;

      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }

  },

  created() {
    console.log(this.isLoading)
  },

  mounted() {
    if (!this.isLoading) {
      this.getRequests()
    }
  }


}
</script>

<style scoped>

</style>
